<template>
    <div>
        <div class="page-wrapper">
            <div class="page-body">
                <div class="row g-2">
                    <div class="col">
                        <div class="card">
                            <div class="card-header">
                                <h3 class="card-title">Configurações da conta</h3>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-lg-6 col-sm-12">
                                        <ul>
                                            <li>
                                                <a href="/users">Usuários da conta</a>
                                            </li>
                                            <li>
                                                <a href="/account/company">Dados da empresa</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import EventBus from '../services/event-bus'

export default {
    name: "AccountPage",
    mounted() {
        EventBus.emit('set-header-title', 'Configurações da conta')
    }
};
</script>