<template>
    <div>
        <div class="collapse navbar-collapse" id="navbar-menu">
            <div class="row">
                <div class="col mt-3">
                    <HeaderLogo />
                </div>
            </div>
            <ul class="navbar-nav pt-lg-3">
                <li class="nav-item">
                    <a class="nav-link" href="/">
                        <span class="nav-link-icon d-md-none d-lg-inline-block">
                            <i class="ti ti-home"></i>
                        </span>
                        <span class="nav-link-title">Dashboard</span>
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="/workspaces">
                        <span class="nav-link-icon d-md-none d-lg-inline-block">
                            <i class="ti ti-box"></i>
                        </span>
                        <span class="nav-link-title">Workspaces</span>
                    </a>
                </li>
                <li class="nav-item" v-if="isAdmin">
                    <a class="nav-link" href="/account">
                        <span class="nav-link-icon d-md-none d-lg-inline-block">
                            <i class="ti ti-building"></i>
                        </span>
                        <span class="nav-link-title">Configurações da conta</span>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import HeaderLogo from './HeaderLogo.vue';
import { store } from '../store';

export default {
    name: "SidebarMenu",
    components: {
        HeaderLogo
    },
    data() {
        return {
            isAdmin: false
        }
    },
    mounted(){
        if (store.user){
            this.isAdmin = store.user.is_admin;
        }
    }
};
</script>