<template>
    <div>
        <div>
            <div class="page-wrapper">
                <div class="page-body">
                    <div class="row mb-3">

                        <div class="col">
                            <!-- Page pre-title -->
                            <div class="page-pretitle">
                                Reports
                            </div>
                            <h2 class="page-title">
                                Status da conta
                            </h2>
                        </div>

                        <!-- Page title actions -->
                        <div class="col-12 col-md-auto ms-auto d-print-none">
                            <div class="btn-list">
                                
                            </div>
                        </div>

                    </div>

                    <div class="row mt-3 mb-3">
                        <div class="col">
                            <!-- Account Status -->
                            <div class="row mb-3">
                                <div class="col-sm-6 col-lg-3">
                                    <div class="card">
                                        <div class="card-body">
                                            <div class="d-flex align-items-center">
                                                <div class="subheader">Flows</div>
                                            </div>
                                            <div class="h1 mb-3">{{flows_count}}</div>
                                            <div class="d-flex mb-2">
                                                <div>Flows ativos</div>
                                                <div class="ms-auto">
                                                    <span v-if="flows_count_percent < 75" class="text-green d-inline-flex align-items-center lh-1">{{flows_count_percent}}%</span>
                                                    <span v-if="flows_count_percent > 75 && flows_count_percent < 90" class="text-orange d-inline-flex align-items-center lh-1">{{flows_count_percent}}%</span>
                                                    <span v-if="flows_count_percent > 90" class="text-red d-inline-flex align-items-center lh-1">{{flows_count_percent}}%</span>
                                                </div>
                                            </div>
                                            <div class="progress progress-sm mb-2">
                                                <div class="progress-bar bg-primary" v-bind:style="'width: '+flows_count_percent+'%'" role="progressbar" v-bind:aria-valuenow="flows_count_percent" aria-valuemin="0" aria-valuemax="100" v-bind:aria-label="flows_count_percent + '% Complete'">
                                                    <span class="visually-hidden">{{flows_count_percent}}% Complete</span>
                                                </div>
                                            </div>
                                            <small class="text-muted">Limite da conta: {{formatHumanNumber(flows_count_limit)}}</small>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-sm-6 col-lg-3">
                                    <div class="card">
                                        <div class="card-body">
                                            <div class="d-flex align-items-center">
                                                <div class="subheader">Execuções de fluxos</div>
                                            </div>
                                            <div class="h1 mb-3">{{formatHumanNumber(flows_executions_total)}}</div>
                                            <div class="d-flex mb-2">
                                                <div>Execuções no mês</div>
                                                <div class="ms-auto">
                                                    <span v-if="flows_executions_percent < 75" class="text-green d-inline-flex align-items-center lh-1">{{flows_executions_percent}}%</span>
                                                    <span v-if="flows_executions_percent > 75 && flows_executions_percent < 90" class="text-orange d-inline-flex align-items-center lh-1">{{flows_executions_percent}}%</span>
                                                    <span v-if="flows_executions_percent > 90" class="text-red d-inline-flex align-items-center lh-1">{{flows_executions_percent}}%</span>
                                                </div>
                                            </div>
                                            <div class="progress progress-sm mb-2">
                                                <div class="progress-bar bg-primary" v-bind:style="'width: '+flows_executions_percent+'%'" role="progressbar" v-bind:aria-valuenow="flows_executions_percent" aria-valuemin="0" aria-valuemax="100" v-bind:aria-label="flows_executions_percent + '% Complete'">
                                                    <span class="visually-hidden">{{flows_executions_percent}}% Complete</span>
                                                </div>
                                            </div>
                                            <small class="text-muted">Limite da conta: {{formatHumanNumber(flows_executions_limit)}} de execuções</small>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-sm-6 col-lg-3">
                                    <div class="card">
                                        <div class="card-body">
                                            <div class="d-flex align-items-center">
                                                <div class="subheader">Storage</div>
                                            </div>
                                            <div class="h1 mb-3">{{storage_used.toFixed(2)}}Gb</div>
                                            <div class="d-flex mb-2">
                                                <div>Storage utilizado</div>
                                                <div class="ms-auto">
                                                    <span v-if="storage_used_percent < 75" class="text-green d-inline-flex align-items-center lh-1">{{storage_used_percent}}%</span>
                                                    <span v-if="storage_used_percent > 75 && storage_used_percent < 90" class="text-orange d-inline-flex align-items-center lh-1">{{storage_used_percent}}%</span>
                                                    <span v-if="storage_used_percent > 90" class="text-red d-inline-flex align-items-center lh-1">{{storage_used_percent}}%</span>
                                                </div>
                                            </div>
                                            <div class="progress progress-sm mb-2">
                                                <div class="progress-bar bg-primary" v-bind:style="'width: '+storage_used_percent+'%'" role="progressbar" v-bind:aria-valuenow="storage_used_percent" aria-valuemin="0" aria-valuemax="100" v-bind:aria-label="storage_used_percent + '% Complete'">
                                                    <span class="visually-hidden">{{storage_used_percent}}% Complete</span>
                                                </div>
                                            </div>
                                            <small class="text-muted">Limite da conta: {{storage_limit}}Gb</small>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-sm-6 col-lg-3">
                                    <div class="card">
                                        <div class="card-body">
                                            <div class="d-flex align-items-center">
                                                <div class="subheader">Database</div>
                                            </div>
                                            <div class="h1 mb-3">{{database_used.toFixed(2)}}Gb</div>
                                            <div class="d-flex mb-2">
                                                <div>Storage para Database utilizado</div>
                                                <div class="ms-auto">
                                                    <span v-if="database_used_percent < 75" class="text-green d-inline-flex align-items-center lh-1">{{database_used_percent}}%</span>
                                                    <span v-if="database_used_percent > 75 && database_used_percent < 90" class="text-orange d-inline-flex align-items-center lh-1">{{database_used_percent}}%</span>
                                                    <span v-if="database_used_percent > 90" class="text-red d-inline-flex align-items-center lh-1">{{database_used_percent}}%</span>
                                                </div>
                                            </div>
                                            <div class="progress progress-sm mb-2">
                                                <div class="progress-bar bg-primary" v-bind:style="'width: '+database_used_percent+'%'" role="progressbar" v-bind:aria-valuenow="database_used_percent" aria-valuemin="0" aria-valuemax="100" v-bind:aria-label="database_used_percent + '% Complete'">
                                                    <span class="visually-hidden">{{database_used_percent}}% Complete</span>
                                                </div>
                                            </div>
                                            <small class="text-muted">Limite da conta: {{database_limit}}Gb</small>
                                        </div>
                                    </div>
                                </div>

                                <!-- <div class="col-sm-6 col-lg-3">
                                    <div class="card">
                                        <div class="card-body">
                                            <div class="d-flex align-items-center">
                                                <div class="subheader">Usuários</div>
                                            </div>
                                            <div class="h1 mb-3">{{users_count}}</div>
                                            <div class="d-flex mb-2">
                                                <div>Usuários cadastrados</div>
                                                <div class="ms-auto">
                                                    <span v-if="users_count_percent < 75" class="text-green d-inline-flex align-items-center lh-1">{{users_count_percent}}%</span>
                                                    <span v-if="users_count_percent > 75 && users_count_percent < 90" class="text-orange d-inline-flex align-items-center lh-1">{{users_count_percent}}%</span>
                                                    <span v-if="users_count_percent > 90" class="text-red d-inline-flex align-items-center lh-1">{{users_count_percent}}%</span>
                                                </div>
                                            </div>
                                            <div class="progress progress-sm mb-2">
                                                <div class="progress-bar bg-primary" v-bind:style="'width: '+users_count_percent+'%'" role="progressbar" v-bind:aria-valuenow="users_count_percent" aria-valuemin="0" aria-valuemax="100" v-bind:aria-label="users_count_percent + '% Complete'">
                                                    <span class="visually-hidden">{{users_count_percent}}% Complete</span>
                                                </div>
                                            </div>
                                            <small class="text-muted">Limite da conta: {{users_limit}} usuários</small>
                                        </div>
                                    </div>
                                </div> -->
                                
                            </div>

                            <!-- Executions by workspace -->

                            <div v-if="workspaces.length && flows_executions_last_24hours">
                                <div class="row mt-4 mb-3" v-for="workspace in workspaces" :key="workspace._id">
                                    <div class="col">
                                        <h4 class="mb-2">
                                            Execuções nas últimas 24 horas em "{{workspace.name}}"
                                        </h4>
                                        <div class="card">
                                            <div class="card-body">
                                                <div class="chart-container" style="position: relative; min-height:200px; width:100%">
                                                    <canvas height="200" :id="'chart-executions-' + workspace._id"></canvas>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row mt-4 mb-3" v-if="account_historical_executions">
                                <div class="col">
                                    <h4 class="mb-2">
                                        Histórico de execuções de fluxos nos últimos 30 dias
                                    </h4>
                                    <div class="card">
                                        <div class="card-body">
                                            <div class="chart-container" style="position: relative; min-height:200px; width:100%">
                                                <canvas height="200" id="chart-account-history"></canvas>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row mt-4 mb-3" v-if="account_historical_executions_monthly.length">
                                <div class="col">
                                    <h4>Histórico de execuções mensais</h4>
                                    <div class="card">
                                        <div class="card-body">
                                            <table class="table">
                                                <thead>
                                                    <tr>
                                                        <th>Mês</th>
                                                        <th>Execuções</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="item in account_historical_executions_monthly" :key="item.month">
                                                        <td>{{formatMonth(item.month)}}</td>
                                                        <td>{{item.count}}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import ErrorPageComponent from '../components/ErrorPageComponent.vue'
import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);

import moment from 'moment-timezone';
import { store } from "../store";
import EventBus from "../services/event-bus";
import Api from "../services/api";

export default {
    name: "AccountStatusPage",
    components: {
        
    },
    data() {
        return {
            workspaces: [],
            flows_count: 0,
            flows_count_percent: 0,
            flows_count_limit: 0,
            flows_executions_total: 0,
            flows_executions_limit: 0,
            flows_executions_percent: 0,
            storage_used: 0,
            storage_used_percent: 0,
            storage_limit: 0,
            database_used: 0,
            database_used_percent: 0,
            database_limit: 0,
            users_count: 0,
            users_count_percent: 0,
            users_limit: 0,
            flows_executions_last_24hours: {},
            account_historical_executions: [],
            account_historical_executions_monthly: []
        }
    },
    async mounted() {
        store.showSidebar = true;
        store.showHeader = true;

        EventBus.emit("set-header-title", "Dashboard");
        EventBus.emit('set-workspace', null);

        await this.loadWorkspaces();
        await this.loadStatus();
        await this.loadAccountHistory();
        await this.loadCharts();
    },
    methods: {
        async loadWorkspaces() {
            try {
                let response = await Api.workspaces.all();
                this.workspaces = response.data;
            } catch (error) {
                console.log(error);
            }
        },
        async loadStatus() {
            var response = await Api.reporting.getCurrentStatusData();
            
            this.flows_count_limit = response.data.account_limits.flows;
            this.flows_count = response.data.account_usage.flows;
            this.flows_count_percent = ((this.flows_count / this.flows_count_limit) * 100).toFixed(0);

            this.flows_executions_total = response.data.account_usage.flows_executions;
            this.flows_executions_limit = response.data.account_limits.flows_executions;
            this.flows_executions_percent = ((this.flows_executions_total / this.flows_executions_limit) * 100).toFixed(0);

            this.storage_limit = response.data.account_limits.storage;
            this.storage_used = response.data.account_usage.storage;
            this.storage_used_percent = ((this.storage_used / this.storage_limit) * 100).toFixed(0);

            this.database_limit = response.data.account_limits.database;
            this.database_used = response.data.account_usage.database;
            this.database_used_percent = ((this.database_used / this.database_limit) * 100).toFixed(0);

            this.users_limit = response.data.account_limits.users;
            this.users_count = response.data.account_usage.users;
            this.users_count_percent = ((this.users_count / this.users_limit) * 100).toFixed(0);

            this.flows_executions_last_24hours = response.data.historical_data.flows_executions_last_24hours;
        },
        async loadAccountHistory() {
            this.account_historical_executions = []

            var period_start = moment().subtract(30, 'days').format('YYYY-MM-DD');
            var period_end = moment().format('YYYY-MM-DD');
            var response = await Api.reporting.getAccountHistoryData(period_start, period_end);
            this.account_historical_executions = response.data;

            this.account_historical_executions_monthly = []
            var responseMonthly = await Api.reporting.getAccountHistoryMonthlyData();
            this.account_historical_executions_monthly = responseMonthly.data;
        },
        async loadCharts() {
            this.workspaces.forEach((workspace) => {
                this.renderFlowExecutionsLast24HoursChart(workspace)
            })

            this.renderAccountHistoryChart()
        },
        formatHumanNumber(number) {
            if (!number) return 0;
            return number.toLocaleString('pt-BR');
        },
        formatMonth(string) {
            moment.locale('pt-BR');
            return moment(string, 'YYYY-MM').format('MMM/YYYY')
        },
        renderFlowExecutionsLast24HoursChart(workspace) {
            // data for chart
            var chartData = [];
            var colors = [];

            // transform to array
            for (var key in this.flows_executions_last_24hours[workspace._id]) {
                chartData.push({
                    key: key,
                    count: this.flows_executions_last_24hours[workspace._id][key]
                })
            }
            
            chartData.reverse()

            // map values by color
            var values = chartData.map(function (item, index) {
                if (index > 0) {
                    colors.push('#206bc4')
                } else {
                    colors.push('#113f76')
                }
                return item.count;
            });

            // map labels
            var labels = chartData.map(function (item, index) {
                var datetime = moment.utc(item.key).tz('America/Sao_Paulo');

                if (index == 0) {
                    return 'Nesta hora ' + datetime.format('HH:mm') + ')';
                }

                if (datetime.format('YYYY-MM-DD') == moment().format('YYYY-MM-DD')) {
                    return datetime.format('HH:mm')
                } else {
                    return datetime.format('DD/MM HH:mm')
                }
            });

            // render chart
            var ctx = document.getElementById("chart-executions-" + workspace._id);
            new Chart(ctx, {
                type: 'bar',
                data: {
                    labels: labels,
                    datasets: [{
                        label: null,
                        data: values,
                        backgroundColor: colors,
                    }]
                },
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                        legend: {
                            display: false
                        },
                        tooltip: {
                            callbacks: {
                                label: function(context) {
                                    return context.raw
                                }
                            }
                        }
                    }
                }
            });
        },
        renderAccountHistoryChart(){
            // data for chart
            var values = this.account_historical_executions.map(function (item) {
                return item.data.flow_executions;
            });
            var labels = this.account_historical_executions.map(function (item) {
                return moment.utc(item.date).format('DD/MM/YYYY');
            });

            // render chart
            var ctx = document.getElementById("chart-account-history")
            new Chart(ctx, {
                type: 'bar',
                data: {
                    labels: labels,
                    datasets: [{
                        label: 'Execuções de fluxos',
                        data: values,
                        backgroundColor: '#0ca678',
                    }]
                },
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                        legend: {
                            display: false
                        },
                        tooltip: {
                            callbacks: {
                                label: function(context) {
                                    return context.raw
                                }
                            }
                        }
                    }
                }
            });
        },
    }
};
</script>