<template>
    <div>Redirecionando...</div>
</template>

<script>
import { store } from "../store";
import Api from "../services/api";
import EventBus from "../services/event-bus";

export default {
    name: "LoginAsCustomerPage",
    data() {
        return {};
    },
    methods: {
        async processLogin() {
            try {
                localStorage.removeItem("adminUserId");
                localStorage.removeItem('token')
                localStorage.removeItem('user')
                localStorage.removeItem('tokenExpiration')
                localStorage.removeItem('workspace');

                var token = this.$route.query.token
                var adminUserId = this.$route.query.adminUserId
                var data = JSON.parse(atob(token.split(".")[1]))
                
                localStorage.setItem("loginAsCustomer", true)
                localStorage.setItem("adminUserId", adminUserId)
                localStorage.setItem("token", token)
                localStorage.setItem("tokenExpiration", data.exp * 1000)

                var userResponse = await Api.users.me()
                EventBus.emit("updated-user", userResponse.data)
                this.$router.push("/")

            } catch (error) {
                console.error(error)
            }
        }
    },
    mounted() {
        store.showSidebar = false
        store.showHeader = false
        EventBus.emit("set-header-title", "")

        this.processLogin()
    },
};
</script>