<template>
    <div class="container-tight py-4">
        <div class="card card-md">
            <div class="card-body">
                <h2 class="card-title text-center mb-4">
                    <img
                        src="@/assets/logo-blue-black.svg"
                        width="230"
                        alt="Floui.io - Integração sem limites"
                    />
                </h2>
                <div class="mb-3">
                    <label class="form-label">Nova senha de acesso:</label>
                    <div class="input-group input-group-flat">
                        <input
                        type="password"
                        class="form-control"
                        placeholder="Senha"
                        autocomplete="off"
                        v-model="password"
                        v-bind="{type: showPassword ? 'text' : 'password'}"
                        />
                        <span class="input-group-text">
                        <a @click="toggleShowPassword()" class="link-secondary" data-bs-toggle="tooltip">
                            <i class="ti ti-eye"></i>
                        </a>
                        </span>
                    </div>
                </div>
                <div class="mb-3">
                    <label class="form-label">Confirme a nova senha:</label>
                    <div class="input-group input-group-flat">
                        <input
                        type="password"
                        class="form-control"
                        placeholder="Senha"
                        autocomplete="off"
                        v-model="confirmPassword"
                        v-bind="{type: showPassword ? 'text' : 'password'}"
                        />
                        <span class="input-group-text">
                            <a @click="toggleShowPassword()" class="link-secondary" data-bs-toggle="tooltip">
                                <i class="ti ti-eye"></i>
                            </a>
                        </span>
                    </div>
                </div>
                <div class="form-footer">
                    <button
                        type="submit"
                        class="btn btn-primary w-100"
                        @click="requestRecoveryPasswordChange()">
                        Continuar
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { store } from "../store"
import Api from "../services/api"
import EventBus from "../services/event-bus"

export default {
    name: "RecoveryPasswordChangePage",
    data() {
        return {
            showPassword: false,
            password: "",
            confirm_password: ""
        };
    },
    methods: {
        async requestRecoveryPasswordChange() {
            if (this.password !== this.confirmPassword) {
                EventBus.emit("message", {
                    type: "error",
                    message: "As senhas não conferem."
                });
                return;
            }

            var result = await Api.recoveryPasswordChange(this.$route.params.email, this.$route.params.token, this.password)

            if (result.data.success) {
                this.$router.push("/login")
                EventBus.emit("message", {
                    type: "success",
                    message: "Senha alterada com sucesso!"
                })
            } else {
                EventBus.emit("message", {
                    type: "error",
                    message: result.data.message
                })
            }
        },
        toggleShowPassword(){
            this.showPassword = !this.showPassword
        }
    },
    mounted() {
        store.showSidebar = false
        store.showHeader = false
        EventBus.emit("set-header-title", "")
    }
}
</script>