import { reactive } from 'vue'

export const store = reactive({
    showSidebar: true,
    sidebarSize: 'full',
    showHeader: true,
    showBackButton: false,
    user: null,
    workspace: null,
    backUrl: null,
    showDebugger: false
})