<template>
    <div>
        <h2>Redirecionando...</h2>
    </div>
</template>

<script>
import Api from '../services/api'
import EventBus from '../services/event-bus'

export default {
    name: "Oauth2CallbackPage",
    async mounted() {
        var auth_type = this.$route.params.auth_type

        var token = document.location.hash && document.location.hash.split('token=')
            ? document.location.hash && document.location.hash.split('token=')[1]
            : null;
        var code = this.$route.query.code ?? null
        var state = this.$route.query.state // service_account_id + '_' + workspace_id

        if ((!code && !token) || !state || state.split('_').length != 2) {
            this.$router.push('/')
            return
        }

        var service_account_id = state.split('_')[0]
        var workspace_id = state.split('_')[1]

        try {
            var response = await Api.serviceAccounts.oauth2.requestToken(auth_type, {
                service_account_id: service_account_id,
                workspace_id: workspace_id,
                code: code,
                token: token
            })

            var workspaceResponse = await Api.workspaces.get(workspace_id)
            var workspace = workspaceResponse.data
            
            var redirectCallbackUrl = workspace.engine_frontend_url + 'oauth2/callback?data=' + btoa(JSON.stringify(response.data))
            document.location.href = redirectCallbackUrl

        } catch (error) {
            var error_message = error.response.data.message
                ?? error.response.data.error
                ?? error.response.data

            EventBus.emit("message", {
                type: "danger",
                message: "Erro ao autorizar conta: " + error_message
            })

            this.$router.push('/workspaces')
        }
    }
};
</script>